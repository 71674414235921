import React, {useState} from 'react';

function SideOption(props) {

  const [style, setStyle] = useState('SideOptionA');
  const changeStyle = (mouseEntered) =>{

      setStyle(mouseEntered? 'SideOptionB' :'SideOptionA');
    };
return <a onMouseEnter ={() =>changeStyle(true)}  onMouseLeave ={() =>changeStyle(false)}href="#" className={style}>{props.option}</a>
}

export default SideOption;
