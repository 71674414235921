import React from 'react';
import logo from './logo.svg';
import './App.css';
import SideBox from './SideBox/SideBox';

function App() {


  //                                    ;
    //                                });

  return (
    <div className="App">
    <SideBox/>

    <div className="Box2" />
    </div>
  );
}

export default App;
