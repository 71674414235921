import React, {useState} from 'react';
import SideOption from '../SideOption/SideOption';


function SideBox() {

  return <div className="SideBox" >
   <SideOption option="Encoder/Decoder"/>
   <SideOption option = "Encryption/Decryption"/>


  </div>
}

export default SideBox;
